var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.orderedTimeline),function(entry,idx){return _c('div',{key:idx + '_timeline_entry2'},[(
					(entry.type === 'event+file' || entry.type === 'offer') &&
					entry.active &&
					(entry.status === 'AWAITING_CONFIRMATION' ||
						entry.status === 'OFFER_ACCEPTED_AWAITING_PAYMENT' ||
						entry.status === 'INVOICE_CREATED')
				)?_c('div',[_c('v-card',{staticClass:"mb-4 timeline-card d-flex",attrs:{"elevation":"1"}},[_c('div',{staticClass:"card-icon"},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-check-outline")])],1),_c('div',{staticClass:"timeline-card-content"},[(entry.status === 'AWAITING_CONFIRMATION')?_c('h4',[_vm._v("Angebot bereit")]):_vm._e(),(entry.status === 'OFFER_ACCEPTED_AWAITING_PAYMENT')?_c('h4',[_vm._v("Angebot akzeptiert")]):_vm._e(),(entry.status === 'INVOICE_CREATED')?_c('h4',[_vm._v("Rechnung erstellt")]):_vm._e(),_c('p',[_vm._v(" "+_vm._s(entry.text)+" "),_c('br'),_c('a',{attrs:{"href":entry.file.url,"target":"_blank"}},[_vm._v(_vm._s(entry.file.name))])]),_c('h6',[_vm._v(_vm._s(_vm.formatDate(entry.createdAt)))])])])],1):_vm._e(),(entry.type === 'event' && entry.status === 'AWATING_UPLOAD')?_c('div',[_c('v-card',{staticClass:"mb-4 timeline-card d-flex",attrs:{"elevation":"1"}},[_c('div',{staticClass:"card-icon"},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-file-upload-outline")])],1),_c('div',{staticClass:"timeline-card-content"},[_c('h4',[_vm._v("Upload benötigt")]),_c('p',[_vm._v(" Wir benötigen folgende Datei von dir. Bitte clicke rechts auf das Upload Button um uns die benötigten Dateien zu schicken. ")]),_c('h6',[_vm._v(_vm._s(_vm.formatDate(entry.createdAt)))])])])],1):_vm._e(),(entry.type === 'event' && entry.status === 'PRODUCTION')?_c('div',[_c('v-card',{staticClass:"mb-4 timeline-card d-flex",attrs:{"elevation":"1"}},[_c('div',{staticClass:"card-icon"},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-pot-steam")])],1),_c('div',{staticClass:"timeline-card-content"},[_c('h4',[_vm._v("In Produktion")]),_c('p',[_vm._v(" Wir benötigen folgende Datei von dir. Bitte clicke rechts auf das Upload Button um uns die benötigten Dateien zu schicken. ")]),_c('h6',[_vm._v(_vm._s(_vm.formatDate(entry.createdAt)))])])])],1):_vm._e(),(
					entry.type === 'event' &&
					entry.status !== 'PRODUCTION' &&
					entry.status !== 'AWATING-UPLOAD' &&
					entry.status !== 'AWAITING-CONFIRMATION'
				)?_c('div',[_c('v-card',{staticClass:"mb-4 timeline-card d-flex",attrs:{"elevation":"1"}},[_c('div',{staticClass:"card-icon"},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-adjust")])],1),_c('div',{staticClass:"timeline-card-content"},[_c('h4',[_vm._v(_vm._s(entry.text))]),_c('p',[_vm._v(_vm._s(entry.text))]),_c('h6',[_vm._v(_vm._s(_vm.formatDate(entry.createdAt)))])])])],1):_vm._e(),(entry.type === 'customer-message')?_c('div',[_c('v-card',{staticClass:"mb-4 timeline-card d-flex",attrs:{"elevation":"1"}},[_c('div',{staticClass:"card-icon"},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-message-outline")])],1),_c('div',{staticClass:"timeline-card-content"},[_c('h4',[_vm._v("Nachricht von "+_vm._s(entry.customer.firstname)+" "+_vm._s(entry.customer.lastname))]),_c('p',{staticClass:"quote"},[_vm._v("\""+_vm._s(entry.text)+"\"")]),_c('h6',[_vm._v(_vm._s(_vm.formatDate(entry.createdAt)))])])])],1):_vm._e(),(entry.type === 'message')?_c('div',[_c('v-card',{staticClass:"mb-4 timeline-card d-flex",attrs:{"elevation":"1"}},[_c('div',{staticClass:"card-icon"},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-message")])],1),_c('div',{staticClass:"timeline-card-content"},[_c('h4',[_vm._v("Nachricht von werc / "+_vm._s(entry.user.firstname))]),_c('p',{staticClass:"quote"},[_vm._v("\""+_vm._s(entry.text)+"\"")]),_c('h6',[_vm._v(_vm._s(_vm.formatDate(entry.createdAt)))])])])],1):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }