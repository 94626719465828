<template>
	<div class="bank-transfer-container">
		<div v-if="loading">
			<v-progress-circular indeterminate color="black"></v-progress-circular>
		</div>
		<div v-if="!loading" class="success-wrapper">
			Danke für deine Auswahl! <br />
			Wir schicken dir alle nötigen Informationen per E-Mail an {{ currentProject.customer.email }}.
			<v-btn class="werc-btn mt-4" color="black" elevation="0" @click="closeModal">Zurück zum Projekt!</v-btn>
		</div>
	</div>
</template>

<script>
import { setPaymentService } from '@/api/payments.api'

export default {
	name: 'BankTransfer',
	props: {
		currentProject: { type: Object, required: true },
		closeModal: { type: Function, required: true },
	},
	data() {
		return {
			loading: true,
		}
	},
	mounted() {
		setPaymentService(this.currentProject._id, 'bank-transfer').then((res) => {
			this.loading = false
			console.log(res)
		})
	},
}
</script>

<style lang="sass" scoped>
.bank-transfer-container
	font-weight: 500
	font-size: 14px
	padding: 40px 0
	.success-wrapper
		font-weight: 700
		text-align: center
		font-size: 18px
		line-height: 1.5em
.werc-btn
	border-radius: 0
	margin-bottom: 10px
	width: 200px
	color: white
	font-weight: 700
	text-transform: none
	font-size: 16px
	letter-spacing: 0
</style>