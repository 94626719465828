<template>
	<div>
		<div v-if="!loading && currentProject" class="project-background d-flex justify-center">
			<div class="project-tabs">
				<div class="tab-btn" :class="{ active: activeTab == 'timeline' }" @click="activeTab = 'timeline'">Verlauf</div>
				<div class="tab-btn" :class="{ active: activeTab == 'overview' }" @click="activeTab = 'overview'">Übersicht</div>
				<div class="tab-btn" :class="{ active: activeTab == 'files' }" @click="activeTab = 'files'">Dateien</div>
				<!-- <div class="tab-btn" :class="{ active: activeTab == 'quote' }" @click="activeTab = 'quote'">Angebot</div> -->
			</div>
			<div class="project-contents d-flex">
				<div class="timeline-wrapper" v-if="activeTab == 'timeline'">
					<Timeline />
					<div class="space-filler"></div>
				</div>
				<div v-if="activeTab == 'overview'" class="overview-wrapper">
					<div v-for="(configuration, idx) in currentProject.configurations" :key="idx + '_conf'">
						<v-card elevation="1" class="mb-4 timeline-card">
							<div class="timeline-card-content">
								<ConfigurationCard :configuration="configuration" :idx="idx" :cartOverlay="false" :filesAlt="files" />
							</div>
						</v-card>
					</div>
					<div class="space-filler"></div>
				</div>
				<div v-if="activeTab === 'files'">
					<ProjectFiles />
				</div>
				<!-- <div v-if="activeTab == 'quote'">
					<Quote :project="currentProject" />
				</div> -->
			</div>
			<Sidebar />
		</div>

		<div v-else class="fill-height">
			<v-row class="fill-height no-gutters">
				<v-col cols="12" class="fill-height d-flex justify-center items-center pa-10">
					<v-progress-circular indeterminate color="black"></v-progress-circular>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
// import dayjs from 'dayjs'

import ConfigurationCard from '@/views/cart/partials/ConfigurationCard'
import Timeline from './partials/Timeline'
import Sidebar from './partials/Sidebar'
import ProjectFiles from './partials/ProjectFiles'
import { EventBus } from '../../utils/eventbus'
// import Quote from './partials/Quote'

export default {
	name: 'Project',
	components: { ConfigurationCard, Timeline, Sidebar, ProjectFiles },
	data() {
		return {
			loading: false,
			activeTab: 'timeline',
		}
	},
	computed: {
		...mapFields('Account', {
			files: 'files',
		}),
		...mapGetters({
			customer: 'Auth/getCustomer',
			currentProject: 'Requests/getCurrentRequest',
		}),
	},
	created() {
		console.log('created')
		this.$store.dispatch('ConfiguratorDisplay/setStep', 'account')

		// event for switching tabs
		EventBus.$on('switchProjectTab', this.switchTab)
	},
	mounted() {
		const projectId = this.$route.params.projectId

		if (projectId) {
			this.loading = true
			this.$store
				.dispatch('Requests/fetchRequestById', projectId)
				.then((res) => {
					if (!res) {
						this.$router.push({ name: 'account-dashboard' })
						return
					}

					return this.$store.dispatch('Account/fetchRequestFiles', projectId)
					// return this.$store.dispatch('Account/fetchCustomerFiles', this.customer._id)
					// return this.$store.dispatch('Account/fetchFiles', res.fileIds)
				})
				.finally(() => {
					this.loading = false
					console.log(this.currentProject)
				})

			// join websocket room
			this.$socket.client.emit('join_request', `client-${projectId}`)
		} else this.$router.push({ name: 'account-dashboard' })
	},
	methods: {
		switchTab(tab) {
			this.activeTab = tab
		},
		accept() {
			const requestId = this.$route.params.projectId
			this.$store.dispatch('Requests/acceptQuote', { requestId })
		},
		getVariantPrice(configurationId, variantId) {
			const configuration = this.currentProject.configurationPrices.find((cp) => cp.configurationId.toString() === configurationId)
			if (configuration) {
				const variant = configuration.variants.find((v) => v.variantId.toString() === variantId)
				return variant ? this.formatPrice(variant.pricePerItem) : this.formatPrice(0)
			} else {
				return this.formatPrice(0)
			}
		},
		getVariantAmount(variant) {
			return variant.sizes.reduce((acc, s) => s.amount + acc, 0)
		},
		getTotalPrice() {
			let total = 0
			this.currentProject.configurations.forEach((c) => {
				for (let key in c.variants) {
					const v = c.variants[key]
					const price = this.getVariantPrice(c.internalId, key)
					const amount = this.getVariantAmount(v)
					total += price * amount
				}
			})
			return this.formatPrice(total)
		},
		formatPrice(price) {
			return price.toFixed(2)
		},
	},
}
</script>


<style scoped lang="sass">
.project-contents
	background: rgba(248,248,248)
	display: flex
	margin: 0 20px 0 20px
	width: 500px
	.timeline-wrapper
		width: 100%
	.overview-wrapper
		width: 100%
	.space-filler
		height: 100px
		width: 100%

.project-tabs
	position: sticky
	display: flex
	flex-direction: column
	top: 0
	width: 120px
	.tab-btn
		font-weight: 700
		letter-spacing: -0.04em
		font-size: 1.9em
		// margin: 0 0 10px 0
		// padding: 7px 18px
		color: rgb(218,218,218)
		display: inline-flex
		justify-content: flex-start
		&:hover
			cursor: pointer
		&.active
			// background: black
			color: black
</style>