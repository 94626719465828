<template>
	<div class="sticky-sidebar">
		<div v-if="loading" class="d-flex justify-center">
			<v-progress-circular indeterminate color="black"></v-progress-circular>
		</div>
		<div v-else>
			<v-card elevation="1" class="mb-4 timeline-card">
				<div class="status-display">
					<div class="d-flex justify-between">
						<div class="pull-left">
							<h6>STATUS</h6>
							<div
								v-if="
									isExpired &&
									(currentProject.status === 'AWAITING_CONFIRMATION' ||
										currentProject.status === 'OFFER_ACCEPTED_AWAITING_PAYMENT')
								"
							>
								<h2 style="line-height: 1.1em">Angebot abgelaufen</h2>
							</div>
							<h2 v-else style="line-height: 1.1em">{{ statusTexts[currentProject.status] }}</h2>

							<!-- <div style="word-break: break-all">{{ currentProject.status }}</div> -->
						</div>

						<div class="text-right" v-if="currentProject.assignedUser">
							<h6>Zuständig</h6>
							<div class="d-flex justify-end" style="margin-top: 6px">
								<div class="profile-image" :style="`background-image: url('${currentProject.assignedUser.avatar}')`"></div>
								<h2 class="weak small">
									{{ currentProject.assignedUser.firstname }} {{ currentProject.assignedUser.lastname }}
								</h2>
							</div>
						</div>
					</div>
					<div class="d-flex justify-between flex-column mt-4">
						<h6 v-if="currentProject.sevdesk && currentProject.sevdesk.offerPdf">Dokumente</h6>
						<div class="mt-1" v-if="currentProject.sevdesk && currentProject.sevdesk.offerPdf">
							<a :href="currentProject.sevdesk.offerPdf" target="_blank">Angebot {{ currentProject.sevdesk.offerNumber }}</a>
						</div>
						<div class="mt-2" v-if="currentProject.sevdesk && currentProject.sevdesk.orderConfirmationPdf">
							<a :href="currentProject.sevdesk.orderConfirmationPdf" target="_blank"
								>Auftragsbestätigung {{ currentProject.sevdesk.orderConfirmationNumber }}</a
							>
						</div>
						<div class="mt-2" v-if="currentProject.sevdesk && currentProject.sevdesk.invoicePdf">
							<a :href="currentProject.sevdesk.invoicePdf" target="_blank"
								>Rechnung {{ currentProject.sevdesk.invoiceNumber }}</a
							>
						</div>

						<!-- <div style="word-break: break-all">{{ currentProject.status }}</div> -->
					</div>
				</div>

				<div class="dates-display d-flex justify-between">
					<div class="pull-left">
						<h6>eingegangen</h6>
						<h2>{{ formatDateSimple(currentProject.createdAt) }}</h2>

						<h6>Kundendaten</h6>

						<div>{{ currentProject.customer.firstname }} {{ currentProject.customer.lastname }}</div>
						<div>{{ currentProject.customer.address }}</div>
						<div>{{ currentProject.customer.postalCode }} {{ currentProject.customer.city }}</div>
						<div>{{ currentProject.customer.country }}</div>
					</div>

					<div class="text-right">
						<div
							v-if="
								currentProject.status === 'AWAITING_CONFIRMATION' ||
								currentProject.status === 'OFFER_ACCEPTED_AWAITING_PAYMENT' ||
								currentProject.status === 'CUSTOMER_CANCELLATION'
							"
						>
							<h6 v-if="currentProject.status !== 'OFFER_ACCEPTED_AWAITING_PAYMENT'">gültig bis</h6>
							<h6 v-if="currentProject.status === 'OFFER_ACCEPTED_AWAITING_PAYMENT'">zahlbar bis</h6>
							<h2 v-if="!isExpired && currentProject.status !== 'CUSTOMER_CANCELLATION'">
								{{ formatDateSimple(currentProject.expiryDate) }}
							</h2>
							<h2 v-if="isExpired || currentProject.status === 'CUSTOMER_CANCELLATION'">abgelaufen</h2>
						</div>
						<div v-if="currentProject.status !== 'PENDING'">
							<h6>Gesamtpreis</h6>
							<h2 class="total-costs">
								{{ formatPrice(totalPriceWithTax) }}€<br />
								<small
									>inkl.
									{{
										mainAddress &&
										mainAddress.country.shortCode &&
										TAX_TYPES[mainAddress.country.shortCode.toLowerCase()]
											? TAX_TYPES[mainAddress.country.shortCode.toLowerCase()].rate
											: 'n/a'
									}}% MwSt</small
								>
							</h2>
						</div>
					</div>
				</div>

				<div class="payment-method" v-if="currentProject.payment && currentProject.payment.paymentService">
					<h6>Zahlungsmethode:</h6>
					<h2>{{ getPaymentLabel(currentProject.payment.paymentService) }}</h2>
					<h6>Bezahlt am:</h6>
					<h2>{{ formatDateSimple(currentProject.payment.createdAt) }}</h2>
				</div>
			</v-card>

			<!-- <button
			class="big-action-btn"
			@click="paymentModal"
			v-if="currentProject.status === 'OFFER_ACCEPTED_AWAITING_PAYMENT' && !isExpired"
			:disabled="busy"
		>
			{{ !busy ? 'Bezahlen' : 'Bitte warten..' }}
		</button> -->

			<PaymentModal v-if="!isExpired" />

			<button
				class="big-action-btn"
				@click="acceptOffer"
				v-if="currentProject.status === 'AWAITING_CONFIRMATION' && !isExpired"
				:disabled="busy"
			>
				{{ !busy ? 'Annehmen' : 'Bitte warten..' }}
			</button>
			<button
				class="big-action-btn light mt-2"
				@click="declineOffer"
				v-if="currentProject.status === 'AWAITING_CONFIRMATION' && !isExpired && !busy"
				:disabled="busy"
			>
				{{ !busy ? 'Ablehnen' : 'Bitte warten..' }}
			</button>

			<button class="small-action-btn" @click="messageDialogToggle(true)">
				<v-icon small color="black">mdi-message</v-icon> Du hast eine Frage?
			</button>

			<button class="small-action-btn" @click="uploadDialog = true">
				<v-icon small color="black">mdi-upload</v-icon> Datei hochladen
			</button>

			<div :class="{ active: uploadDialog }" class="upload-modal">
				<div class="modal-backdrop" @click="uploadDialog = false"></div>
				<div class="modal-window d-flex align-center justify-center">
					<FileUpload />
					<!-- <button class="big-action-btn submit" @click="uploadFile">Upload</button> -->
				</div>
			</div>

			<div :class="{ active: messageDialog }" class="message-modal">
				<div class="modal-backdrop" @click="messageDialogToggle(false)"></div>
				<div class="modal-window">
					<textarea
						placeholder="Schreib uns eine Nachricht und wir versuchen dir so schnell wie möglich zu antworten..."
						no-resize
						v-model="messageText"
					></textarea>
					<button class="big-action-btn submit" @click="sendMessage">Senden</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PaymentModal from './PaymentModal'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import FileUpload from './FileUpload'
import { EventBus } from '../../../utils/eventbus'
import { mapFields } from 'vuex-map-fields'

export default {
	name: 'Sidebar',
	components: { PaymentModal, FileUpload },
	computed: {
		...mapGetters({
			currentProject: 'Requests/getCurrentRequest',
		}),
		...mapFields('Auth', { customer: 'customer' }),
		isExpired() {
			return new Date().toISOString() > new Date(this.currentProject.expiryDate).toISOString()
		},
		totalPriceWithTax() {
			return this.currentProject.costs.costsTotal + this.currentProject.costs.costsTotal * 0.19
		},
		mainAddress() {
			return this.customer.address.find((a) => a.mainAddress)
		},
	},
	data() {
		return {
			loading: false,
			uploadDialog: false,
			messageDialog: false,
			messageText: null,
			statusTexts: {
				PENDING: 'In Bearbeitung',
				AWAITING_CONFIRMATION: 'Angebot bereit',
				NEW_CUSTOMER_MESSAGE: 'Anfrage in Bearbeitung',
				AWATING_UPLOAD: 'Upload benötigt',
				AWATING_INFO: 'Information benötigt',
				OFFER_ACCEPTED_AWAITING_PAYMENT: 'Zahlung ausstehend',
				INVOICE_CREATED: 'Rechnung erstellt',
				PAYMENT_RECEIVED: 'Zahlung erhalten',
				PROJECT_REFUSED: 'Projekt abgelehnt',
				CUSTOMER_CANCELLATION: 'Projekt vom Kunden abgesagt',
				PRE_PRODUCTION: 'Produktion in Vorbereitung',
				PRODUCTION: 'In Produktion',
				ON_HOLD: 'Produktion pausiert',
				AWAITING_CUSTOMER_CHECK: 'Kundenfreigabe benötigt',
				POST_PRODUCTION: 'In Nachbearbeitung',
				SHIPPED: 'Versendet',
				COMPLETED: 'Projekt Abgeschlossen',
			},
			busy: false,
			TAX_TYPES: {
				Deutschland: { type: 'default', rate: 19 },
				Österreich: { type: 'eu', rate: 20 },
				Schweiz: { type: 'noteu', rate: 0 },
				de: { type: 'default', rate: 19 },
				at: { type: 'eu', rate: 20 },
				ch: { type: 'noteu', rate: 0 },
			},
		}
	},
	created() {
		this.loading = true
		this.$store.dispatch('Auth/getCustomerData').finally(() => (this.loading = false))

		EventBus.$on('closeUploadModal', () => {
			this.uploadDialog = false
		})
	},
	methods: {
		getPaymentLabel(service) {
			const labels = {
				'bank-transfer': 'Bank-Überweisung',
				klarna: 'Klarna',
				paypal: 'PayPal',
			}
			return labels[service]
		},
		formatDateSimple(date) {
			return dayjs(date).format('DD.MM.YYYY')
		},
		messageDialogToggle(val) {
			this.messageDialog = val
		},
		sendMessage() {
			if (this.messageText && this.messageText.trim().length > 0) {
				const requestId = this.$route.params.projectId
				this.$store.dispatch('Requests/addMessage', { requestId, type: 'customer-message', text: this.messageText.trim() })
				this.messageDialogToggle(false)
				this.messageText = null
			}
		},
		getTotalPrice() {
			let total = 0
			this.currentProject.configurations.forEach((c) => {
				for (let key in c.variants) {
					const v = c.variants[key]
					const price = this.getVariantPrice(c.internalId, key)
					const amount = this.getVariantAmount(v)
					total += price * amount
				}
			})
			return this.formatPrice(total)
		},
		getVariantPrice(configurationId, variantId) {
			const configuration = this.currentProject.configurationPrices.find((cp) => cp.configurationId.toString() === configurationId)
			if (configuration) {
				const variant = configuration.variants.find((v) => v.variantId.toString() === variantId)
				return variant ? this.formatPrice(variant.pricePerItem) : this.formatPrice(0)
			} else {
				return this.formatPrice(0)
			}
		},
		getVariantAmount(variant) {
			return variant.sizes.reduce((acc, s) => s.amount + acc, 0)
		},
		formatPrice(price) {
			return price.toFixed(2)
		},
		acceptOffer() {
			this.busy = true
			this.$store.dispatch('Requests/acceptOffer', { requestId: this.currentProject._id }).finally(() => (this.busy = false))
		},
		declineOffer() {
			this.busy = true
			this.$store.dispatch('Requests/declineOffer', { requestId: this.currentProject._id }).finally(() => (this.busy = false))
		},
		paymentModal() {
			this.busy = true
			setTimeout(() => {
				this.busy = false
				alert('Kommt bald!')
			}, 400)
		},
		uploadFile() {
			console.log('upload')
		},
	},
}
</script>

<style lang="sass" scoped>
.sticky-sidebar
	position: sticky
	width: 350px
	top: 0px
	right: 30px
	.status-display
		padding: 20px
		border-bottom: solid 1px rgb(200,200,200)
		h6
			text-transform: uppercase
			font-weight: 700
			letter-spacing: 0.15em
			font-size: 0.8em
			color: rgb(180,180,180)
		h2
			font-weight: 700
			font-size: 1.8em
			&.weak
				font-weight: 400 !important
			&.small
				font-size: 1.2em
		.profile-image
			margin-right: 4px
			width: 20px
			height: 20px
			border-radius: 50%
			background-size: 100%
			background-position: center
		.pull-left, .text-right
			flex-basis: 50%
			flex-grow: 0
			flex-shrink: 0
	.dates-display
		padding: 20px
		h6
			text-transform: uppercase
			font-weight: 700
			letter-spacing: 0.15em
			font-size: 0.8em
			color: rgb(180,180,180)
		h2
			font-weight: 700
			font-size: 1.4em
			margin-bottom: 15px
			&.weak
				font-weight: 400 !important
			&.total-costs
				small
					font-size: 0.5em
					color: rgb(180,180,180)
					position: relative
					top: -10px
					font-weight: 500
	.current-total
		text-align: right
		width: 100%
		padding: 20px
		h2
			font-weight: 700
			text-transform: lowercase !important
			font-size: 1.8em
		span
			font-weight: 400
			font-size: 1.1em !important
			opacity: 0.5
	.payment-method
		padding: 0 20px 20px
		// border-top: solid 1px rgb(200,200,200)
		h6
			text-transform: uppercase
			font-weight: 700
			letter-spacing: 0.15em
			font-size: 0.8em
			color: rgb(180,180,180)
		h2
			font-weight: 700
			font-size: 1.4em
			margin-bottom: 15px

.message-modal, .upload-modal
	position: fixed
	height: 100vh
	width: 100vw
	top: 0
	left: 0
	z-index: 9999
	display: inline-flex
	justify-content: center
	align-items: center
	pointer-events: none
	opacity: 0
	.modal-backdrop
		position: absolute
		background: rgb(0,0,0,0.5)
		top: 0
		left: 0
		height: 100%
		width: 100%
		z-index: 1
	.modal-window
		width: 450px
		height: 550px
		background: white
		border: solid 2px black
		position: relative
		z-index: 2
		textarea
			width: 100%
			height: 100%
			resize: none
			padding: 20px
			font-size: 1.3em
			&::placeholder
				color: rgb(50,50,50)
		.submit
			position: absolute
			bottom: 0
			left: 0
	&.active
		pointer-events: initial
		opacity: 1

.big-action-btn
	background: black
	width: 100%
	text-align: center
	color: white
	padding: 10px
	font-weight: 700
	font-size: 1.5em
	letter-spacing: -0.02em
	&.light
		background: rgba(0,0,0,0.5)
.small-action-btn
	background: transparent
	width: 100%
	text-align: center
	color: black
	padding: 10px
	font-weight: 700
	font-size: 1.1em
	letter-spacing: -0.02em
</style>
