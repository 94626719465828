<template>
	<div class="py-0">
		<!-- <AccountHeader /> -->
		<v-row class="d-flex justify-center no-gutters mt-5">
			<v-col cols="6" class="card text-center">
				<v-row class="table-top">
					<v-col cols="6" class="d-flex align-center justify-start"> <b>E-Mail</b> </v-col>
					<v-col cols="4" class="d-flex align-center justify-start"> <b>verifiziert?</b> </v-col>
					<v-col cols="2" class="text-right"> <b>Löschen</b> </v-col>
				</v-row>
				<v-row v-for="(entry, idx) in customer.additionalEmails" :key="idx" class="table-row-entry">
					<v-col cols="6" class="d-flex align-center justify-start">
						<span class="email">{{ entry.email }}</span>
					</v-col>
					<v-col cols="4" class="d-flex align-center justify-start">
						<h6>{{ entry.verified ? 'verifiziert' : 'noch nicht verifiziert' }}</h6>
					</v-col>
					<v-col cols="2" class="text-left">
						<v-btn class="shadow-none" dark small @click="() => deleteEmail(entry.email)" color="error">
							<v-icon dark> mdi-delete </v-icon>
						</v-btn>
					</v-col>
				</v-row>

				<v-row>
					<v-col class="text-left mt-5">
						<div>
							{{ $getDictString('configurator_account_email_info') }}
						</div>
					</v-col>
				</v-row>

				<v-form class="add-email-form" ref="add-email-form" v-model="addEmailValidation" lazy-validation @submit.prevent="addEmail">
					<v-row class="mt-10">
						<v-col cols="8" offset="2" class="d-flex align-start justify-center">
							<v-text-field
								v-model.lazy="addEmailValue"
								class="ma-0 pa-0"
								label="Neue E-Mail hinzufügen"
								:rules="emailRules"
								required
								color="black"
							></v-text-field>
							<v-btn type="submit" color="submit" class="ml-4 shadow-none">Hinzufügen</v-btn>
						</v-col>
					</v-row>
				</v-form>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
// import AccountHeader from './AccountHeader'

export default {
	name: 'Emails',
	// components: { AccountHeader },
	data() {
		return {
			customerDataFormValidation: false,
			addEmailValidation: false,
			countries: ['Deutschland', 'Österreich', 'Schweiz'],
			emailRules: [(v) => !!v || 'Email wird benötigt', (v) => /.+@.+/.test(v) || 'Die Email muss valide sein.'],
			nameRules: [(v) => !!v || 'Name wird benötigt', (v) => (v && v.length > 2) || 'Der Name muss mehr als 2 Buchstaben haben.'],
			countryRules: [(v) => !!v || 'Land wird benötigt.'],
			phoneRules: [(v) => !!v || 'Telefonnummer muss sein.'],
			postCodeRules: [(v) => !!v || 'PLZ muss angegeben werden.'],
			addEmailValue: null,
		}
	},
	computed: {
		...mapGetters({
			customer: 'Auth/getCustomer',
		}),
		customerData() {
			let mainAddress = this.customer.address.find((a) => a.mainAddress) || {}

			const data = {
				surename: this.customer.surename,
				familyname: this.customer.familyname,
				email: this.customer.email,
				country: mainAddress.country.name,
				postalCode: mainAddress.zip,
				phone: this.customer.phone,
			}
			return data
		},
	},
	mounted() {
		this.$store.dispatch('Auth/getCustomerData')
	},
	methods: {
		saveCustomerData() {
			const validCustomerForm = this.$refs['customer-data-form'].validate()

			if (validCustomerForm) this.$store.dispatch('Auth/updateCustomerData', this.customerData)
		},
		addEmail() {
			const validEmailForm = this.$refs['add-email-form'].validate()

			if (validEmailForm)
				this.$store.dispatch('Auth/addEmail', this.addEmailValue).then(() => {
					this.addEmailValue = null
					this.$refs['add-email-form'].reset()
				})
		},
		deleteEmail(email) {
			this.$store.dispatch('Auth/deleteEmail', email)
		},
	},
}
</script>

<style scoped lang="sass">
.header
	border-bottom: solid 2px black
.add-email-form
	margin-top: 80px
.logo
	svg
		height: 30px
h3
	font-weight: 700
	font-size: 24px
	letter-spacing: -0.05em
.card
	b
		opacity: 0.1
		font-size: 40px
		letter-spacing: -0.05em
.table-row-entry
	.email
		font-weight: 700
	h2
		font-weight: 700
		font-size: 24px
		letter-spacing: -0.05em
	h5
		font-weight: 300
		font-size: 20px
	h6
		letter-spacing: 0.1em
		font-weight: 700
		background: black
		color: white
		font-size: 10px
		border-radius: 8px
		padding: 3px 10px
</style>
