<template>
	<v-dialog v-model="showModal" width="800" max-width="100%" scrollable persistent transition="dialog-bottom-transition">
		<template v-slot:activator="{ on, attrs }">
			<v-btn elevation="0" dark small color="error" v-bind="attrs" v-on="on" class="mt-2" :disabled="disabled">
				<v-icon dark> mdi-delete </v-icon>
			</v-btn>
		</template>
		<v-card id="payment-modal">
			<v-toolbar dark color="black" elevation="0" tile class="toolbar">
				<v-toolbar-title>Adresse löschen</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-toolbar>
			<v-card-text v-if="loading">
				<v-row class="mt-5">
					<v-col> Loading... </v-col>
				</v-row>
			</v-card-text>
			<v-card-text v-if="!loading">
				<v-row class="mt-5">
					<v-col> Soll die Adresse wirklich gelöscht werden? </v-col>
				</v-row>
				<v-row>
					<v-col>
						<div>{{ address.name }}</div>
						<div>
							{{ address.street }}
						</div>
						<div>{{ address.zip }} {{ address.city }}</div>
						<div>
							<span class="text-uppercase">{{ address.country.shortCode }}</span> {{ address.country.name }}
						</div>
						<div v-if="address.mainAddress">Hauptadresse</div>
					</v-col>
				</v-row>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-btn color="primary" class="weakBtn" elevation="0" text @click="closeModal" v-if="!loading"> Abbrechen </v-btn>
				<v-spacer></v-spacer>
				<v-btn color="error" class="strongBtn" elevation="0" text @click="deleteAddress" v-if="!loading"> Löschen </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'DeleteAddressModal',
	props: {
		address: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showModal: false,
			loading: false,
		}
	},
	methods: {
		closeModal() {
			this.showModal = false
		},
		deleteAddress() {
			console.log(this.address)
			this.loading = true
			this.$store
				.dispatch('Auth/deleteAddress', this.address._id)
				.then(() => this.$store.dispatch('Auth/fetchCustomerAddresses'))
				.then(() => {
					this.loading = false
					this.closeModal()
				})
				.catch(() => (this.loading = false))
		},
	},
}
</script>
