<template>
	<v-sheet class="backdrop-dots">
		<v-row align="center" class="hero" no-gutters>
			<v-col cols="12" lg="12" class="fill-height right-box d-flex align-center justify-center">
				<v-sheet id="bB" class="box pa-10 mr-5 mb-20" elevation="0" color="white" :outlined="true" width="40%">
					<div class="sub-head mb-5">
						{{ $getDictString('configurator_additional_email_validation_heading') }}
					</div>
					<!-- Validating link -->
					<div v-if="validating">
						<v-progress-circular indeterminate color="black"></v-progress-circular>
					</div>
					<!-- Link is valid -->
					<div v-if="!validating && success">
						<p>
							{{ $getDictString('configurator_additional_email_validation_success_text') }}
						</p>
						<v-btn text large class="link-button displace black-btn" @click="$router.push({ name: 'account-login' })">
							{{ $getDictString('configurator_additional_email_validation_login_link') }}
						</v-btn>
					</div>
					<!-- Link is invalid -->
					<div v-if="!validating && !success">
						<div class="mb-5">
							{{ $getDictString('configurator_additional_email_validation_invalid_link') }}
						</div>
					</div>
				</v-sheet>
			</v-col>
		</v-row>
	</v-sheet>
</template>

<script>
import { verifyAdditionalEmail } from '@/api/customer.api'

export default {
	name: 'VerifyAdditionalEmail',
	data() {
		return {
			validating: true,
			success: true,
		}
	},
	mounted() {
		const { token } = this.$route.params
		if (token) {
			verifyAdditionalEmail(token)
				.then(() => {
					this.validating = false
				})
				.catch(() => {
					this.validating = false
					this.success = false
				})
		}
	},
}
</script>

<style lang="sass" scoped>
.backdrop-dots
	background-image: radial-gradient(rgba(0, 0, 0, 0.15) 10%, #fff 9%)
	background-position: 0 0
	background-size: 20px 20px
	background-repeat: repeat

	.hero
		height: 100vh
		width: 100%
		position: relative

		.box
			border: 1px solid black !important
			position: absolute
			z-index: 100

			.sub-head
				font-weight: 700
				font-size: 2em
				letter-spacing: -0.04em

			.text
				font-weight: 700
				font-size: 1em
				line-height: 2

			.link-button
				font-size: 20px
				font-weight: 700
				text-transform: lowercase
				letter-spacing: -1px
				background: white
				&.displace
					position: relative
					&.right
						right: -75px
					&.left
						left: -75px

			.black-btn
				color: white
				background: black
				&:hover
					background: rgb(60,60,60)
</style>