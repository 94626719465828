import { render, staticRenderFns } from "./ProjectFiles.vue?vue&type=template&id=6e400ece&scoped=true&"
import script from "./ProjectFiles.vue?vue&type=script&lang=js&"
export * from "./ProjectFiles.vue?vue&type=script&lang=js&"
import style0 from "./ProjectFiles.vue?vue&type=style&index=0&id=6e400ece&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e400ece",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
