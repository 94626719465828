<template>
	<v-dialog v-model="showModal" width="800" max-width="100%" scrollable persistent transition="dialog-bottom-transition">
		<template v-slot:activator="{ on, attrs }">
			<div
				v-show="
					currentProject.status === 'OFFER_ACCEPTED_AWAITING_PAYMENT' &&
					((currentProject.payment && !currentProject.payment.paymentService) || !currentProject.payment)
				"
			>
				<button class="big-action-btn my-2" v-bind="attrs" v-on="on">Bezahlen</button>
			</div>
		</template>
		<v-card id="payment-modal">
			<v-toolbar dark color="black" elevation="0" tile class="toolbar">
				<v-toolbar-title>Bezahlung</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-toolbar>
			<v-card-text>
				<div class="modal-content d-flex flex-wrap flex-row align-content-center justify-center pa-5">
					<Klarna :currentProject="currentProject" :closeModal="cancelPayment" v-if="selectedPaymentService === 'klarna'" />
					<Paypal :currentProject="currentProject" :closeModal="cancelPayment" v-if="selectedPaymentService === 'paypal'" />
					<BankTransfer
						:currentProject="currentProject"
						:closeModal="cancelPayment"
						v-if="selectedPaymentService === 'bank-transfer'"
					/>

					<div class="select-payment-service" v-if="!selectedPaymentService">
						<v-card elevation="1" class="mb-4 service-card d-flex" @click="selectPaymentService('klarna')">
							<div class="card-icon">
								<img src="/payments/klarna.png" />
							</div>
							<div class="service-card-content">
								<div class="description">
									<div class="name">
										<h4>Klarna</h4>
									</div>
									<div class="text">Bezahle per Sofortüberweisung, Lastschrift, Kreditkarte oder Klarna-Rechnung.</div>
								</div>
							</div>
						</v-card>
						<v-card elevation="1" class="mb-4 service-card d-flex" @click="selectPaymentService('paypal')">
							<div class="card-icon">
								<img src="/payments/de-pp-logo-200px.png" />
							</div>
							<div class="service-card-content">
								<div class="description">
									<div class="name"><h4>Paypal</h4></div>
									<div class="text">Bezahle mit deinem Paypal Account.</div>
								</div>
							</div>
						</v-card>
						<v-card elevation="1" class="mb-4 service-card d-flex" @click="selectPaymentService('bank-transfer')">
							<div class="card-icon bank-transfer">
								<img src="/payments/cheque2.png" />
							</div>
							<div class="service-card-content">
								<div class="description">
									<div class="name"><h4>Bank-Überweisung</h4></div>
									<div class="text">Erhalte die Rechnung per E-Mail und überweise den Betrag auf unser Konto.</div>
								</div>
							</div>
						</v-card>
					</div>
				</div>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-btn color="primary" class="weakBtn" text @click="cancelPayment"> Abbrechen </v-btn>
				<v-spacer></v-spacer>
				<!-- <v-btn color="primary" class="lightBtn" text @click="back" v-if="!klarnaLoading && klarnaShowContainer"> Zurück </v-btn>
				<v-btn color="primary" class="strongBtn" text @click="authorize" v-if="!klarnaLoading && klarnaShowContainer">
					Weiter
				</v-btn> -->
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

import Klarna from './Klarna'
import Paypal from './Paypal'
import BankTransfer from './BankTransfer'

export default {
	name: 'PaymentModal',
	components: { Klarna, Paypal, BankTransfer },
	computed: {
		...mapGetters({
			currentProject: 'Requests/getCurrentRequest',
		}),
	},
	// watch: {
	// 	currentProject(val) {
	// 		console.log('currentProject watcher')
	// 		console.log(val)
	// 	},
	// },
	data() {
		return {
			showModal: false,
			paymentSuccess: false,
			order_lines: [],
			selectedPaymentService: null,
		}
	},
	mounted() {
		this.klarnaShowNextButton = false
	},
	methods: {
		selectPaymentService(service) {
			this.selectedPaymentService = service
		},
		cancelPayment() {
			this.showModal = false
			this.selectedPaymentService = null
			// @TODO: reset klarna
		},
	},
}
</script>

<style lang="sass" scoped>
.big-action-btn
	background: black
	width: 100%
	text-align: center
	color: white
	padding: 10px
	font-weight: 700
	font-size: 1.5em
	letter-spacing: -0.02em
	&.light
		background: rgba(0,0,0,0.5)

#payment-modal
	font-weight: 700
	// height: 100%
	.toolbar, .v-toolbar
		border-radius: 4px 4px 0 0 !important
		border: solid 1px transparent
		.v-toolbar__title
			font-size: 2em !important
	.modal-content
		// height: calc(100% - 56px - 60px)
		// overflow-y: auto
	.strongBtn
		background: black
		color: white !important
		text-transform: none
		font-weight: 700
		font-size: 1em
		letter-spacing: -0.01em
		padding: 20px 20px
	.lightBtn
		background: grey
		color: white !important
		text-transform: none
		font-weight: 700
		font-size: 1em
		letter-spacing: -0.01em
		padding: 20px 20px
	.weakBtn
		background: white
		color: black !important
		text-transform: none
		font-weight: 700
		font-size: 1em
		letter-spacing: -0.01em
		padding: 20px 20px
		border: solid 2px black
	// .klarna-container
	//     &.hide
	//         opacity: 0
	//         height: 0
	.select-payment-service
		width: 100%

		.service-card
			width: 100%
			cursor: pointer
			&:hover
				background-color: rgba(0,0,0,0.02)
			.card-icon
				padding: 0px 20px
				margin-right: 5px
				display: flex
				align-items: center
				justify-content: center
				img
					width: 80px
				&.bank-transfer
					img
						padding: 0 10px

			.service-card-content
				padding: 25px 15px 25px 15px
				width: 100%
				.quote-card
					width: 450px // temporary
			h4
				font-size: 1.7em
				letter-spacing: -0.04em
				line-height: 1.1
				font-weight: 700
				margin-bottom: 10px
			.description
				.text
					font-weight: 500
</style>