<template>
	<div class="py-0">
		<!-- <AccountHeader /> -->
		<v-row v-if="!loading">
			<v-col cols="6" offset="3">
				<div class="heading"><b>Dateien</b></div>
				<div class="file-count mb-2">insgesamt {{ filteredFiles.length }} Dateien</div>
				<div class="my-5 d-flex justify-center items-center flex-row files-wrapper">
					<div v-for="(file, idx) in filteredFiles" :key="idx" class="file-wrapper">
						<div class="file-row d-flex justify-start items-center">
							<a :href="file.url" target="_blank">
								<img :src="file.url" class="mr-5" />
							</a>

							<div class="details d-flex flex-column">
								<h3>{{ file.label }}</h3>
								<p class="mb-1">Hochgeladen am {{ formatDate(file.createdAt) }}</p>
								<p class="mb-1">Bildgröße: {{ file.width }} x {{ file.height }}</p>
								<p class="mb-1">
									Dateityp: <span class="text-uppercase">{{ file.fileType }}</span>
								</p>
								<div>
									<v-btn text :href="file.url" target="_blank">
										<v-icon dark> mdi-cloud-download-outline </v-icon>
									</v-btn>
									<!-- <v-btn text>
								<v-icon dark class="pr-2"> mdi-delete-outline </v-icon>
							</v-btn> -->
								</div>
							</div>
						</div>
					</div>
					<!-- <div class="dummy"></div> -->
				</div>
			</v-col>
		</v-row>
		<div v-else class="fill-height">
			<v-row class="fill-height no-gutters">
				<v-col cols="12" class="fill-height d-flex justify-center items-center pa-10">
					<v-progress-circular indeterminate color="black"></v-progress-circular>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
// import AccountHeader from './AccountHeader'
import dayjs from 'dayjs'

export default {
	name: 'Files',
	// components: { AccountHeader },
	data() {
		return {
			tab: null,
			loading: true,
		}
	},
	computed: {
		...mapGetters({
			customer: 'Auth/getCustomer',
			requests: 'Requests/getLoggedInCustomerRequests',
			allFiles: 'Account/getFiles',
		}),
		...mapFields('Account', {
			allFiles: 'files',
		}),
		filteredFiles() {
			return this.allFiles.filter((f) => f.type === 'upload')
		},
	},
	mounted() {
		this.loading = true
		this.$store
			.dispatch('Requests/fetchForLoggedInCustomer')
			.then(this.$store.dispatch('Account/fetchCustomerFiles', this.customer._id))
			.finally(() => (this.loading = false))
	},
	methods: {
		formatDate(date) {
			return dayjs(date).format('DD.MM.YYYY')
		},
	},
}
</script>

<style scoped lang="sass">
.heading
	b
		opacity: 0.1
		font-size: 40px
		letter-spacing: -0.05em
.file-count
	color: #b4b4b4
	font-size: 0.8em
	font-weight: 700
	text-transform: uppercase
	letter-spacing: 0.15em
.files-wrapper
	flex-wrap: wrap
	.file-wrapper
		flex: 1 1 50%
.file-row
	margin: 15px 0
	img
		height: 120px
		width: 120px
		object-fit: contain
	.details
		// width: 30vw
		h3
			font-size: 32px
			font-weight: 700
			letter-spacing: -0.05em
.dummy
	height: 200px
	width: 100%
</style>