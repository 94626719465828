<template>
	<v-sheet class="backdrop-dots">
		<v-row align="center" class="hero" no-gutters>
			<v-col cols="12" lg="12" class="fill-height right-box d-flex align-center justify-center">
				<v-sheet id="bB" class="box pa-10 mr-5 mb-20" elevation="0" color="white" :outlined="true" width="40%" v-if="!requestSend">
					<div class="sub-head">{{ $getDictString('configurator_start_heading_email') }}</div>
					<div>
						<v-form ref="customer-email-form" v-model="formValidation" lazy-validation @submit.prevent="requestMagicLink">
							<v-text-field
								v-model="emailAddress"
								placeholder="E-Mail"
								:rules="emailRules"
								required
								class="email-address-input"
							/>
						</v-form>
					</div>
					<div class="text-p">
						{{ $getDictString('configurator_start_text_email') }}
					</div>
					<div class="pt-5">
						<v-row class="pa-0 ma-0">
							<v-col class="pa-0 ma-0 d-flex justify-start" cols="6">
								<v-btn text large class="link-button" @click="$router.push({ name: 'configurator-start' })">
									{{ $getDictString('configurator_start_button_email_back') }}
								</v-btn>
							</v-col>
							<v-col class="pa-0 ma-0 d-flex justify-end" cols="6">
								<v-btn outlined tile large class="link-button displace right black-btn" @click="requestMagicLink"
									>{{ $getDictString('configurator_start_button_email_magic_link') }}
								</v-btn>
							</v-col>
						</v-row>
					</div>
				</v-sheet>
				<v-sheet id="bB" class="box pa-10 mr-20 mb-20" elevation="0" color="white" :outlined="true" width="30%" v-if="requestSend">
					<div class="sub-head">{{ 'Du hast Post' }}</div>
					<div class="text-p">Schaue in deinem E-Mail Postfach nach.</div>
					<div class="pt-5">
						<v-row class="pa-0 ma-0">
							<v-col class="pa-0 ma-0 d-flex justify-start" cols="6">
								<v-btn text large class="link-button" @click="requestSend = false">
									{{ $getDictString('configurator_start_button_email_back') }}
								</v-btn>
							</v-col>
						</v-row>
					</div>
				</v-sheet>
			</v-col>
		</v-row>
	</v-sheet>
</template>

<script>
export default {
	name: 'MagicLink',
	data() {
		return {
			emailAddress: '',
			formValidation: false,
			emailRules: [(v) => !!v || 'Email wird benötigt', (v) => /.+@.+/.test(v) || 'Die Email muss valide sein.'],
			requestSend: false,
			requestFailed: false,
		}
	},
	methods: {
		openConfigurator() {
			this.$store.dispatch('Configurator/resetConfiguration')
			this.$router.push({ name: 'configurator-config' })
		},
		requestMagicLink() {
			const validForm = this.$refs['customer-email-form'].validate()

			if (validForm) {
				const email = this.emailAddress.trim()
				console.log('send magic link: ', email)
				this.requestSend = true

				const data = { email }
				if (this.$route.query.org) data.orgUrl = this.$route.query.org

				this.$store
					.dispatch('Auth/requestMagicLink', data)
					.then((res) => {
						console.log(res)
					})
					.catch((err) => {
						console.log(err)
						this.requestFailed = true
					})
			} else {
				console.log('email is not valid')
			}
		},
	},
}
</script>

<style scoped lang="sass">
.backdrop-dots
	// background-image: radial-gradient(rgba(0, 0, 0, 0.15) 10%, #fff 9%)
	// background-position: 0 0
	// background-size: 20px 20px
	// background-repeat: repeat
h1
	font-weight: 700
	font-size: 6em
	letter-spacing: -0.05em
	line-height: 1
h2
	font-weight: 700
	font-size: 4.5em
	letter-spacing: -0.03em
h4
	font-size: 1.6em
	font-weight: 300
h5
	font-size: 1.3em
	font-weight: 700

.bigButton
	display: inline-block
	font-weight: 700
	background: black
	border: solid 3px black
	padding: 10px 25px
	color: white
	font-size: 1.4em
.logo
	position: fixed
	top: 30px
	left: 10px
	z-index: 100
	svg
		width: 100% !important
.user-button
	position: fixed
	right: 20px
	top: 30px
	z-index: 20
	a
		background: black
		color: white
		padding: 10px 20px
		font-weight: 700
		font-size: 1.2em
		margin: 0 5px
		&.register
			background: transparent
			border: solid 2px black
			color: black
#scroll
	position: absolute
	right: 40px
	bottom: 40px
	height: 60px
	width: 60px
.hero
	height: 100vh
	width: 100%
	position: relative

.outlined-heading
	font-weight: 700
	font-size: 50px
	letter-spacing: -2px
	color: white
	text-shadow: 0px 0px 2px #000
.sub-head
	font-weight: 700
	font-size: 2em
	letter-spacing: -0.04em
.text
	font-weight: 700
	font-size: 1em
	line-height: 2

.link-button
	font-size: 20px
	font-weight: 700
	text-transform: lowercase
	letter-spacing: -1px
	background: white
	&.displace
		position: relative
		&.right
			right: -75px
		&.left
			left: -75px
.black-btn
	color: white
	background: black
	&:hover
		background: rgb(60,60,60)
.box
	border: 1px solid black !important
	position: absolute
	z-index: 100
</style>
