<template>
	<div class="py-0">
		<!-- <AccountHeader /> -->
		<v-row class="d-flex justify-center no-gutters mt-5">
			<v-col cols="6" class="card d-flex flex-column justify-center">
				<div class="heading">
					<b>Benutzerdaten</b>
				</div>
				<v-form
					ref="customer-data-form"
					class="text-center"
					v-model="customerDataFormValidation"
					lazy-validation
					@submit.prevent="saveCustomerData"
				>
					<v-row>
						<v-col>
							<v-text-field
								v-model="customerData.surename"
								:rules="nameRules"
								:label="$getDictString('configurator_cart_label_contact_firstname')"
								required
								color="black"
								class="mt-5"
								:disabled="loading"
							></v-text-field>
						</v-col>
						<v-col>
							<v-text-field
								v-model="customerData.familyname"
								:rules="nameRules"
								:label="$getDictString('configurator_cart_label_contact_lastname')"
								required
								color="black"
								class="mt-5"
								:disabled="loading"
							></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-text-field
								v-model="customerData.email"
								:rules="emailRules"
								:label="$getDictString('configurator_cart_label_contact_email')"
								required
								color="black"
								:disabled="loading"
							></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-text-field
								v-model="customerData.phone"
								:rules="phoneRules"
								:label="$getDictString('configurator_cart_label_contact_phone')"
								required
								color="black"
								:disabled="loading"
							></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn elevation="0" type="submit" :disabled="loading">Speichern</v-btn>
						</v-col>
					</v-row>
				</v-form>
			</v-col>
		</v-row>
		<!-- <AccountHeader /> -->
		<v-row class="d-flex justify-center no-gutters mt-5 pt-5">
			<v-col cols="6" class="card d-flex flex-column justify-center">
				<div class="heading">
					<b>Adressen</b>
				</div>
				<div v-for="(address, idx) in customerAddresses" :key="idx" class="py-5">
					<v-row>
						<v-col class="address-display">
							<div>{{ address.name }}</div>
							<div>
								{{ address.street }}
							</div>
							<div>{{ address.zip }} {{ address.city }}</div>
							<div>
								<span class="text-uppercase">{{ address.country.shortCode }}</span> {{ address.country.name }}
							</div>
							<div v-if="address.mainAddress">Hauptadresse</div>
						</v-col>
						<v-col class="d-flex flex-column align-end">
							<div>
								<EditAddressModal :address="address" :disabled="loading" />
							</div>
							<div v-if="!address.mainAddress">
								<v-btn
									elevation="0"
									dark
									small
									color="lime"
									@click="setAsMainAddress(address)"
									class="mt-2"
									:disabled="loading"
								>
									<v-icon dark> mdi-map-marker </v-icon>
								</v-btn>
							</div>
							<div v-if="!address.mainAddress">
								<DeleteAddressModal :address="address" :disabled="loading" />
							</div>
						</v-col>
					</v-row>
					<hr class="my-5" v-if="customerAddresses.length > 1 && idx !== customerAddresses.length - 1" />
				</div>
				<v-row class="my -2">
					<v-col class="d-flex justify-center">
						<AddAddressModal :disabled="loading" />
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

import EditAddressModal from './partials/EditAddressModal.vue'
import AddAddressModal from './partials/AddAddressModal.vue'
import DeleteAddressModal from './partials/DeleteAddressModal.vue'

export default {
	name: 'Settings',
	components: { EditAddressModal, AddAddressModal, DeleteAddressModal },
	data() {
		return {
			customerDataFormValidation: false,
			addEmailValidation: false,
			countries: ['Deutschland', 'Österreich', 'Schweiz'],
			emailRules: [(v) => !!v || 'Email wird benötigt', (v) => /.+@.+/.test(v) || 'Die Email muss valide sein.'],
			nameRules: [(v) => !!v || 'Name wird benötigt', (v) => (v && v.length > 2) || 'Der Name muss mehr als 2 Buchstaben haben.'],
			countryRules: [(v) => !!v || 'Land wird benötigt.'],
			phoneRules: [(v) => !!v || 'Telefonnummer muss sein.'],
			postCodeRules: [(v) => !!v || 'PLZ muss angegeben werden.'],
			addEmailValue: null,
			loading: false,
		}
	},
	computed: {
		...mapFields('Auth', {
			customerAddresses: 'customerAddresses',
			customer: 'customer',
		}),
		customerData() {
			if (this.customer) {
				return this.customer
			} else {
				return {
					surename: '',
					familyname: '',
					email: '',
					phone: '',
				}
			}
		},
	},
	mounted() {
		this.$store.dispatch('Auth/getCustomerData').then(() => this.$store.dispatch('Auth/fetchCustomerAddresses'))
	},
	methods: {
		saveCustomerData() {
			const validCustomerForm = this.$refs['customer-data-form'].validate()

			if (validCustomerForm) {
				this.loading = true
				this.$store
					.dispatch('Auth/updateCustomerData', this.customerData)
					.then(() => this.$store.dispatch('Auth/getCustomerData'))
					.finally(() => {
						this.loading = false
					})
			}
		},
		addEmail() {
			const validEmailForm = this.$refs['add-email-form'].validate()

			if (validEmailForm)
				this.$store.dispatch('Auth/addEmail', this.addEmailValue).then(() => {
					this.addEmailValue = null
					this.$refs['add-email-form'].reset()
				})
		},
		deleteEmail(email) {
			this.$store.dispatch('Auth/deleteEmail', email)
		},
		setAsMainAddress(address) {
			address.mainAddress = true
			this.$store.dispatch('Auth/updateAddress', address).then(() => this.$store.dispatch('Auth/fetchCustomerAddresses'))
		},
	},
}
</script>

<style scoped lang="sass">
.heading
	b
		opacity: 0.1
		font-size: 40px
		letter-spacing: -0.05em
.header
	border-bottom: solid 2px black
.logo
	svg
		height: 30px
h3
	font-weight: 700
	font-size: 24px
	letter-spacing: -0.05em
.card
	b
		opacity: 0.1
		font-size: 40px
		letter-spacing: -0.05em
.table-row-entry
	h2
		font-weight: 700
		font-size: 24px
		letter-spacing: -0.05em
	h5
		font-weight: 300
		font-size: 20px
	h6
		letter-spacing: 0.1em
		font-weight: 700
		background: black
		color: white
		font-size: 10px
		border-radius: 8px
		padding: 3px 10px

.address-display
	font-size: 18px
</style>
