<template>
	<div id="wrapper">
		<v-stepper width="800px" v-model="step" elevation="0">
			<v-stepper-header class="no-shadow">
				<v-stepper-step :complete="step > 1" step="1" color="black" @click="changeStep(1)"> Bezahlart </v-stepper-step>

				<v-divider></v-divider>

				<v-stepper-step :complete="step > 2" step="2" color="black" @click="changeStep(2)"> Bezahlung </v-stepper-step>

				<v-divider></v-divider>

				<v-stepper-step step="3" color="black"> Fertig! </v-stepper-step>
			</v-stepper-header>

			<v-stepper-items>
				<v-stepper-content step="1">
					<v-card elevation="0" min-height="200px" v-if="!loading">
						<div class="payment-method-categories">
							<v-btn
								class="werc-btn"
								v-for="(cat, catIdx) in paymentMethodCategories"
								:key="catIdx"
								@click="selectPaymentMethodCategory(cat.identifier)"
								elevation="0"
								color="black"
							>
								{{ cat.name }}
							</v-btn>
						</div>
					</v-card>
					<v-card elevation="0" v-else height="200px" class="d-flex justify-center align-center">
						<v-progress-circular indeterminate color="black"></v-progress-circular>
					</v-card>
				</v-stepper-content>

				<v-stepper-content step="2">
					<v-card elevation="0" min-height="200px">
						<div class="plugin-wrapper">
							<div id="plugin-container" v-show="!placingOrder && !paymentSuccess">
								<div id="klarna-payments-container"></div>
							</div>
							<div v-if="pluginLoaded && !placingOrder">
								<v-btn class="werc-btn" elevation="0" color="black" @click="authorize">Bezahlvorgang starten</v-btn>
							</div>
							<div v-if="placingOrder">
								<v-progress-circular indeterminate color="black"></v-progress-circular>
							</div>
						</div>
					</v-card>
				</v-stepper-content>

				<v-stepper-content step="3">
					<v-card elevation="0" min-height="200px" class="d-flex flex-column justify-center align-center success-wrapper">
						Danke für deine Bezahlung! <br />
						Wir bereiten jetzt die Produktion vor.
						<v-btn class="werc-btn mt-4" color="black" elevation="0" @click="closeModal">Zurück zum Projekt!</v-btn>
					</v-card>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
		<!-- <div class="ml-5" v-if="showContainer">
			<div style="font-size: 20px" class="mb-2">Test Werte:</div>
			<div class="mb-2"><span style="font-weight: 300">IBAN:</span> DE11520513735120710131</div>
			<div class="mb-2">
				<span style="font-weight: 300; text-decoration: underline">Sofortüberweisung:</span><br />
				<span style="font-weight: 300">Bank:</span> Suche nach "demo" -> Demo Bank <br />
				<span style="font-weight: 300">Passwort:</span> beliebig <br />
				<span style="font-weight: 300">OTP:</span> beliebige 6-stellige Nummer (999999 führt zu Abbruch)
			</div>
			<div class="mb-2">
				<span style="font-weight: 300; text-decoration: underline">Kreditkarte:</span><br />
				<span style="font-weight: 300">Credit card number:</span> 4111 1111 1111 1111<br />
				<span style="font-weight: 300">CVV:</span> 123<br />
				<span style="font-weight: 300">Exp:</span> 12/25
				<span style="font-weight: 300">(Or any other valid date in the future)</span>
			</div>
			<div class="mb-5">
				<a href="https://docs.klarna.com/assets/media/d47db3ea-2f3c-40b2-b8b5-52a65034746d/test_id_card.png" target="_blank"
					>Ausweis-Dokument</a
				>
			</div>
		</div> -->
	</div>
</template>

<script>
import { getKlarnaSession, placeKlarnaOrder, setPaymentService } from '@/api/payments.api'

export default {
	name: 'Klarna',
	props: {
		currentProject: { type: Object, required: true },
		closeModal: { type: Function, required: true },
	},
	data() {
		return {
			step: 1,
			loading: true,
			clientToken: null,
			loadData: null,
			paymentMethodCategories: null,
			selectedPaymentMethodCategory: null,
			paymentSuccess: false,
			pluginLoaded: false,
			placingOrder: false,
		}
	},
	mounted() {
		this.loading = true
		this.getSession()
		console.log('mounted')
	},
	methods: {
		changeStep(step) {
			if (!this.paymentSuccess) this.step = step
		},
		getSession() {
			const that = this
			getKlarnaSession(this.currentProject._id).then((res) => {
				console.log('GOT SESSION')
				console.log(res)
				that.loading = false
				console.log(that.loading)
				this.clientToken = res.session_data.client_token
				this.loadData = res.load_data
				this.paymentMethodCategories = res.session_data.payment_method_categories
			})
		},
		selectPaymentMethodCategory(category) {
			this.selectedPaymentMethodCategory = category
			this.step = 2
			this.pluginLoaded = false
			const that = this

			// remove klarna container if available
			const elem = document.getElementById('klarna-payments-container')
			if (elem) {
				elem.parentNode.removeChild(elem)
				const newContainer = document.createElement('div')
				newContainer.setAttribute('id', 'klarna-payments-container')
				document.getElementById('plugin-container').appendChild(newContainer)
			}

			// klarna script
			let klarnaScript = document.createElement('script')
			klarnaScript.setAttribute('src', 'https://x.klarnacdn.net/kp/lib/v1/api.js')
			document.head.appendChild(klarnaScript)

			window.klarnaAsyncCallback = function () {
				// This is where you start calling Klarna's JS SDK functions
				try {
					// init klarna
					window.Klarna.Payments.init({ client_token: that.clientToken })

					// load klarna
					window.Klarna.Payments.load(
						{
							container: '#klarna-payments-container',
							payment_method_category: category,
						},
						that.loadData,
						function (res) {
							that.pluginLoaded = true

							if (res.show_form) {
								console.log('LOAD SUCCESS')
								console.debug(res)
							} else {
								console.log('LOAD FAILED')
								console.log(res)
							}
						}
					)
				} catch (err) {
					console.error(err)
				}
			}
		},
		authorize() {
			const that = this
			that.placingOrder = true
			// authorize klarna
			try {
				window.Klarna.Payments.authorize(
					{
						payment_method_category: this.selectedPaymentMethodCategory,
					},
					that.loadData,
					function (authorizeData) {
						console.debug(authorizeData)
						if (authorizeData.approved) {
							setPaymentService(that.currentProject._id, 'klarna').then((setPaymentServiceResult) => {
								console.log(setPaymentServiceResult)
								placeKlarnaOrder(that.currentProject._id, authorizeData)
									.then((placeOrderResult) => {
										console.log(placeOrderResult)
										that.step = 3
										that.paymentSuccess = true
										that.placingOrder = false
									})
									.catch((err) => {
										console.error(err)
										alert('Da ist etwas schief gelaufen..')
									})
							})
							// placeKlarnaOrder(that.currentProject._id, authorizeData).then((placeOrderResult) => {
							// 	console.log(placeOrderResult)
							// 	setPaymentService(that.currentProject._id, 'klarna').then((setPaymentServiceResult) => {
							// 		console.log(setPaymentServiceResult)
							// 		that.step = 3
							// 		that.paymentSuccess = true
							// 	})
							// })
						} else {
							alert('Da ist was schief gelaufen')
						}
						// Note: An authorization_token is valid for 60 minutes. We can only guarantee that a token will work for placing orders within this timeframe.
					}
				)
			} catch (err) {
				console.error(err)
			}
		},
	},
}
</script>

<style scoped lang="sass">
.no-shadow
	box-shadow: none !important
.payment-method-categories
	display: flex
	justify-content: center
	align-items: center
	flex-direction: column
.werc-btn
	border-radius: 0
	margin-bottom: 10px
	width: 200px
	color: white
	font-weight: 700
	text-transform: none
	font-size: 16px
	letter-spacing: 0
.plugin-wrapper
	display: flex
	justify-content: center
	align-items: flex-start
	.werc-btn
		margin-left: 30px
	#plugin-container
		display: flex
		justify-content: center
		align-items: center
		height: 100%
		overflow-y: auto
		#klarna-payments-container
			width: 200px
.success-wrapper
	font-weight: 700
	text-align: center
	font-size: 18px
	line-height: 1.5em
</style>