<template>
	<div>
		<!-- <AccountHeader /> -->
		<div class="mt-5" v-if="!loading">
			<v-row class="table-top px-5 no-gutters">
				<v-col cols="1" class="pl-5"> <b>No</b> </v-col>
				<v-col cols="2" class="d-flex justify-center"> <b>Status</b> </v-col>
				<v-col cols="5"> <b>Name</b> </v-col>

				<v-col cols="2" class="text-center"> <b>Date</b> </v-col>
				<v-col cols="2" class="text-center pr-5"> <b>Expires</b> </v-col>
			</v-row>
			<v-row
				class="pa-5 table-row-entry no-gutters"
				v-for="request in requests"
				:key="request._id"
				@click="$router.push({ name: 'account-project', params: { projectId: request._id } })"
			>
				<v-col cols="1" class="d-flex justify-start items-center">
					<h2 class="pl-2">{{ request.publicId.replace('#', '') }}</h2>
				</v-col>
				<v-col cols="2" class="d-flex justify-center items-center">
					<h6>{{ request.status }}</h6>
				</v-col>
				<v-col cols="5" class="d-flex justify-start items-center">
					{{ request.projectName }}
				</v-col>
				<v-col cols="2" class="d-flex justify-center items-center">
					<h5>{{ format(request.createdAt) }}</h5>
				</v-col>
				<v-col cols="2" class="d-flex justify-center items-center">
					<h5 class="pr-2">{{ format(request.expiryDate) }}</h5>
				</v-col>
			</v-row>
			<v-row class="py-5 no-gutters">
				<v-col cols="12" class="text-center">
					<a class="bigButton" @click="newConfig">starte ein neues design</a>
				</v-col>
			</v-row>
		</div>
		<div v-else class="fill-height">
			<v-row class="fill-height no-gutters">
				<v-col cols="12" class="fill-height d-flex justify-center items-center pa-10">
					<v-progress-circular indeterminate color="black"></v-progress-circular>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
// import { mapFields } from 'vuex-map-fields'
import dayjs from 'dayjs'
// import AccountHeader from './AccountHeader'

export default {
	name: 'Dashboard',
	// components: { AccountHeader },
	data() {
		return {
			tab: null,
			loading: true,
		}
	},
	computed: {
		...mapGetters({ customer: 'Auth/getCustomer', requests: 'Requests/getLoggedInCustomerRequests' }),
		// ...mapFields('Requests', {
		// 	requests: 'loggedinCustomerRequests',
		// }),
	},
	mounted() {
		this.loading = true
		this.$store.dispatch('Requests/fetchForLoggedInCustomer').then(() => {
			this.loading = false
		})
	},
	methods: {
		logout() {
			this.$store.dispatch('Auth/logout')
			this.$router.push({ name: 'account-login' })
		},
		format(date) {
			return dayjs(date).format('DD.MM.YYYY')
		},
		newConfig() {
			this.$store.dispatch('Configurator/resetConfiguration')
			this.$router.push({ name: 'configurator-config' })
		},
	},
}
</script>

<style scoped lang="sass">
.header
	border-bottom: solid 2px black
.logo
	svg
		height: 30px
h3
	font-weight: 700
	font-size: 24px
	letter-spacing: -0.05em
.bigButton
	display: inline-block
	font-weight: 700
	background: black
	border: solid 3px black
	padding: 10px 25px
	color: white
	font-size: 1.2em
.table-top
	b
		opacity: 0.1
		font-size: 40px
		letter-spacing: -0.05em
.table-row-entry
	border-bottom: solid 1px rgb(180,180,180)
	&:hover
		background: rgb(240,240,240)
		cursor: pointer
	h2
		font-weight: 700
		font-size: 24px
		letter-spacing: -0.05em
	h5
		font-weight: 300
		font-size: 20px
	h6
		letter-spacing: 0.1em
		font-weight: 700
		background: black
		color: white
		font-size: 10px
		border-radius: 8px
		padding: 3px 10px
</style>