import httpClient from './httpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/payment'
// const REQUESTS_END_POINT = process.env.VUE_APP_BASE_URL + '/requests'
// const KLARNA_END_POINT = process.env.VUE_APP_KLARNA_BASE_URL

const getKlarnaSession = (requestId) => {
	return httpClient.get(`${END_POINT}/${requestId}/klarna/session`)
}

const placeKlarnaOrder = (requestId, authorizeData) => {
	return httpClient.post(`${END_POINT}/${requestId}/klarna/place-order`, { authorizeData })
}

const getPaypalData = (requestId) => {
	return httpClient.get(`${END_POINT}/${requestId}/paypal/data`)
}

const setPaypalPayment = (requestId, paypalData) => {
	return httpClient.post(`${END_POINT}/${requestId}/paypal/set-payment`, { paypalData })
}

const setPaymentService = (requestId, paymentService) => {
	return httpClient.post(`${END_POINT}/${requestId}/set-payment-service`, { paymentService })
}

export { getKlarnaSession, setPaymentService, placeKlarnaOrder, getPaypalData, setPaypalPayment }
