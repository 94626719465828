<template>
	<v-dialog v-model="showModal" width="800" max-width="100%" scrollable persistent transition="dialog-bottom-transition">
		<template v-slot:activator="{ on, attrs }">
			<v-btn elevation="0" dark small color="primary" v-bind="attrs" v-on="on" :disabled="disabled">
				<v-icon dark> mdi-pencil </v-icon>
			</v-btn>
		</template>
		<v-card id="payment-modal">
			<v-toolbar dark color="black" elevation="0" tile class="toolbar">
				<v-toolbar-title>Adresse bearbeiten</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-toolbar>
			<v-card-text v-if="loading">
				<v-row class="mt-5">
					<v-col> Loading... </v-col>
				</v-row>
			</v-card-text>
			<v-card-text v-if="!loading">
				<v-row class="mt-5">
					<v-col>
						<v-text-field v-model="address.name" label="Name" color="black"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-text-field v-model="address.street" label="Straße" color="black"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="4">
						<v-text-field v-model="address.zip" label="PLZ" color="black"></v-text-field>
					</v-col>
					<v-col cols="8">
						<v-text-field v-model="address.city" label="Stadt" color="black"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-select
							v-model="address.country"
							:items="countries"
							:label="$getDictString('configurator_cart_lable_contact_country')"
							:rules="countryRules"
							required
							class="mt-5 mb-0"
							color="black"
						></v-select>
					</v-col>
				</v-row>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-btn color="primary" class="weakBtn" text @click="closeModal" v-if="!loading"> Abbrechen </v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary" class="strongBtn" text @click="save" v-if="!loading"> Speichern </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'EditAddressModal',
	props: {
		address: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showModal: false,
			loading: false,
			countries: [
				{ text: 'Deutschland', value: { shortCode: 'DE', name: 'Deutschland' } },
				{ text: 'Österreich', value: { shortCode: 'AT', name: 'Österreich' } },
				{ text: 'Schweiz', value: { shortCode: 'CH', name: 'Schweiz' } },
			],
			countryRules: [(v) => !!v || 'Land wird benötigt.'],
		}
	},
	methods: {
		closeModal() {
			this.showModal = false
		},
		save() {
			console.log(this.address)
			this.loading = true
			this.$store
				.dispatch('Auth/updateAddress', this.address)
				.then(() => this.$store.dispatch('Auth/fetchCustomerAddresses'))
				.then(() => {
					this.loading = false
					this.closeModal()
				})
				.catch(() => (this.loading = false))
		},
	},
}
</script>
