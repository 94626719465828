<template>
	<div>
		<div v-if="validating">
			<v-progress-circular indeterminate color="black"></v-progress-circular>
		</div>
		<div v-if="!validating && (!validated || (validated && !successPostalCode))">
			<span v-if="!validated">{{ $getDictString('configurator_postalcode_validation_subheading') }}</span>
			<span v-if="validated && !successPostalCode">{{ $getDictString('configurator_postalcode_validation_error') }}</span>
			<v-form ref="postal-code-form" v-model="formValidation" lazy-validation @submit.prevent="validatePostalCode">
				<v-text-field
					v-model="postalCode"
					:placeholder="$getDictString('configurator_postalcode_validation_input_placeholder')"
					:rules="postalCodeRules"
					required
					class="postal-code-input"
				/>
				<v-btn text large class="link-button displace black-btn" @click="validatePostalCode">{{
					$getDictString('configurator_postalcode_validation_send_button')
				}}</v-btn>
			</v-form>
		</div>
		<div v-if="!validating && validated && successPostalCode">{{ $getDictString('configurator_postalcode_validation_success') }}</div>
	</div>
</template>

<script>
export default {
	name: 'ValidatePostalCode',
	data() {
		return {
			formValidation: false,
			postalCode: '',
			postalCodeRules: [(v) => !!v || 'PLZ wird benötigt', (v) => (v && v.length >= 4) || 'Die PLZ muss valide sein.'],
			validated: false,
			validating: false,
			successPostalCode: false,
		}
	},
	methods: {
		validatePostalCode() {
			const token = this.$route.params.magicToken
			const postalCode = this.postalCode
			const validForm = this.$refs['postal-code-form'].validate()

			if (validForm) {
				this.validating = true
				this.$store
					.dispatch('Auth/validatePostalCode', { token, postalCode })
					.then(() => {
						this.validating = false
						this.validated = true
						this.successPostalCode = true
						if (this.$route.query && this.$route.query.org) {
							this.$router.push(this.$route.query.org)
						} else {
							this.$router.push({ name: 'account-dashboard' })
						}
					})
					.catch(() => {
						this.validating = false
						this.validated = true
						this.successPostalCode = false
					})
			}
		},
	},
}
</script>

<style lang="sass" scoped>
.link-button
	font-size: 20px
	font-weight: 700
	text-transform: lowercase
	letter-spacing: -1px
	background: white
	&.displace
		position: relative
		&.right
			right: -75px
		&.left
			left: -75px

.black-btn
	color: white
	background: black
	&:hover
		background: rgb(60,60,60)
</style>