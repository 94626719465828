<template>
	<div id="project-files-wrapper">
		<div>
			<div class="file-count mb-2">insgesamt {{ filteredFiles.length }} Dateien</div>
			<div v-for="(file, idx) in filteredFiles" :key="idx" class="file-wrapper">
				<!-- <img :src="file.url" width="80px" />
				<div class="label">{{ file.label }}</div> -->
				<div class="file-row d-flex justify-center items-center">
					<a :href="file.url" target="_blank">
						<img :src="file.url" class="mr-5" />
					</a>

					<div class="details d-flex flex-column">
						<h3>{{ file.label }}</h3>
						<p class="mb-1">Hochgeladen am {{ formatDate(file.createdAt) }}</p>
						<p class="mb-1">Bildgröße: {{ file.width }} x {{ file.height }}</p>
						<p class="mb-1" v-if="file.fileType">
							Dateityp: <span class="text-uppercase">{{ file.fileType }}</span>
						</p>
						<div>
							<v-btn text :href="file.url" target="_blank">
								<v-icon dark> mdi-cloud-download-outline </v-icon>
							</v-btn>
							<!-- <v-btn text>
								<v-icon dark class="pr-2"> mdi-delete-outline </v-icon>
							</v-btn> -->
						</div>
					</div>
				</div>
			</div>
			<div class="dummy"></div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import dayjs from 'dayjs'

export default {
	name: 'ProjectFiles',
	computed: {
		...mapGetters({
			currentProject: 'Requests/getCurrentRequest',
		}),
		...mapFields('Account', {
			files: 'files',
		}),
		filteredFiles() {
			const fileIds = this.currentProject.fileIds.map((id) => id.toString())

			// console.log('Project FileIds:')
			// console.dir(fileIds)
			// console.log('Customer Files:')
			// console.dir(this.files.map((f) => f._id))
			// fileIds.forEach((id) => {
			// 	if (this.files.map((f) => f._id).indexOf(id) === -1) {
			// 		console.log(id)
			// 	}
			// })

			return this.files.filter((file) => fileIds.indexOf(file._id) > -1).sort((a, b) => b.createdAt - a.createdAt)
		},
	},
	methods: {
		formatDate(date) {
			return dayjs(date).format('DD.MM.YYYY, HH:mm')
		},
	},
}
</script>

<style lang="sass" scoped>
#project-files-wrapper
	width: 100%

	.file-count
		color: #b4b4b4
		font-size: 0.8em
		font-weight: 700
		text-transform: uppercase
		letter-spacing: 0.15em

	.dummy
		height: 200px

	.file-wrapper
		.file-row
			margin: 15px 0
			img
				height: 120px
				width: 120px
				object-fit: contain
			.details
				h3
					font-size: 32px
					font-weight: 700
					letter-spacing: -0.05em
</style>