<template>
	<div id="paypal-wrapper">
		<div v-if="loading">
			<v-progress-circular indeterminate color="black"></v-progress-circular>
		</div>
		<div v-show="showPaypalContainer">
			<div id="paypal-container"></div>
		</div>
		<v-card elevation="0" min-height="200px" class="d-flex flex-column justify-center align-center success-wrapper" v-if="success">
			Danke für deine Bezahlung! <br />
			Wir bereiten jetzt die Produktion vor.
			<v-btn class="werc-btn mt-4" color="black" elevation="0" @click="closeModal">Zurück zum Projekt!</v-btn>
		</v-card>
	</div>
</template>

<script>
import { getPaypalData, setPaymentService, setPaypalPayment } from '@/api/payments.api'
import { loadScript } from '@paypal/paypal-js'

export default {
	name: 'Paypal',
	props: {
		currentProject: { type: Object, required: true },
		closeModal: { type: Function, required: true },
	},
	data() {
		return {
			loading: true,
			success: false,
			showPaypalContainer: true,
		}
	},
	mounted() {
		this.initScript()
	},
	methods: {
		initScript() {
			console.log('init paypal script')

			getPaypalData(this.currentProject._id)
				.then((paypalData) => {
					const { payer, purchase_units, application_context, script_options } = paypalData

					script_options.debug = false
					// script_options.debug = true

					const that = this

					loadScript(script_options)
						.then((paypal) => {
							// start to use the PayPal JS SDK script
							console.log(paypal)
							paypal
								.Buttons({
									createOrder: function (data, actions) {
										// Set up the transaction
										return actions.order.create({
											// intent: 'CAPTURE',
											application_context,
											payer,
											purchase_units,
										})
									},
									onApprove: function (data, actions) {
										console.log(data)
										that.showPaypalContainer = false
										that.loading = true

										let paypalTransactionData = null

										// This function captures the funds from the transaction.
										return actions.order
											.capture()
											.then(function (details) {
												// This function shows a transaction success message to your buyer.
												console.log('Transaction completed by ' + details.payer.name.given_name)
												paypalTransactionData = details
												return setPaymentService(that.currentProject._id, 'paypal')
											})
											.then(() => {
												console.log(paypalTransactionData)
												that.success = true
												return setPaypalPayment(that.currentProject._id, paypalTransactionData)
											})
											.catch((error) => {
												console.error(error)
											})
											.finally(() => {
												that.loading = false
											})
									},
									onCancel: function (data) {
										// Show a cancel page, or return to cart
										console.log('You have cancelled the order!')
										console.log(data)
									},
									onError: function (err) {
										console.error(err)
										console.error('Something went wrong!')
										// For example, redirect to a specific error page
										// window.location.href = '/your-error-page-here'
									},
								})
								.render('#paypal-container')
								.catch((error) => {
									console.error('failed to render the PayPal Buttons', error)
								})
						})
						.catch((err) => {
							console.error('failed to load the PayPal JS SDK script', err)
						})
						.finally(() => {
							that.loading = false
						})
				})
				.catch((err) => {
					console.error('Getting Paypal data failed!')
					console.error(err)
				})
		},
	},
}
</script>

<style lang="sass" scoped>
.success-wrapper
	font-weight: 700
	text-align: center
	font-size: 18px
	line-height: 1.5em
.werc-btn
	border-radius: 0
	margin-bottom: 10px
	width: 200px
	color: white
	font-weight: 700
	text-transform: none
	font-size: 16px
	letter-spacing: 0
</style>