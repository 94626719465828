<template>
	<div>
		<div v-for="(entry, idx) in orderedTimeline" :key="idx + '_timeline_entry2'">
			<!-- EVENTS -->
			<div
				v-if="
					(entry.type === 'event+file' || entry.type === 'offer') &&
					entry.active &&
					(entry.status === 'AWAITING_CONFIRMATION' ||
						entry.status === 'OFFER_ACCEPTED_AWAITING_PAYMENT' ||
						entry.status === 'INVOICE_CREATED')
				"
			>
				<v-card elevation="1" class="mb-4 timeline-card d-flex">
					<div class="card-icon"><v-icon color="black">mdi-check-outline</v-icon></div>
					<div class="timeline-card-content">
						<h4 v-if="entry.status === 'AWAITING_CONFIRMATION'">Angebot bereit</h4>
						<h4 v-if="entry.status === 'OFFER_ACCEPTED_AWAITING_PAYMENT'">Angebot akzeptiert</h4>
						<h4 v-if="entry.status === 'INVOICE_CREATED'">Rechnung erstellt</h4>

						<p>
							{{ entry.text }} <br />
							<a :href="entry.file.url" target="_blank">{{ entry.file.name }}</a>
						</p>
						<h6>{{ formatDate(entry.createdAt) }}</h6>
					</div>
				</v-card>
			</div>

			<div v-if="entry.type === 'event' && entry.status === 'AWATING_UPLOAD'">
				<v-card elevation="1" class="mb-4 timeline-card d-flex">
					<div class="card-icon"><v-icon color="black">mdi-file-upload-outline</v-icon></div>
					<div class="timeline-card-content">
						<h4>Upload benötigt</h4>
						<p>
							Wir benötigen folgende Datei von dir. Bitte clicke rechts auf das Upload Button um uns die benötigten Dateien zu
							schicken.
						</p>
						<h6>{{ formatDate(entry.createdAt) }}</h6>
					</div>
				</v-card>
			</div>

			<div v-if="entry.type === 'event' && entry.status === 'PRODUCTION'">
				<v-card elevation="1" class="mb-4 timeline-card d-flex">
					<div class="card-icon"><v-icon color="black">mdi-pot-steam</v-icon></div>
					<div class="timeline-card-content">
						<h4>In Produktion</h4>
						<p>
							Wir benötigen folgende Datei von dir. Bitte clicke rechts auf das Upload Button um uns die benötigten Dateien zu
							schicken.
						</p>
						<h6>{{ formatDate(entry.createdAt) }}</h6>
					</div>
				</v-card>
			</div>

			<div
				v-if="
					entry.type === 'event' &&
					entry.status !== 'PRODUCTION' &&
					entry.status !== 'AWATING-UPLOAD' &&
					entry.status !== 'AWAITING-CONFIRMATION'
				"
			>
				<v-card elevation="1" class="mb-4 timeline-card d-flex">
					<div class="card-icon"><v-icon color="black">mdi-adjust</v-icon></div>
					<div class="timeline-card-content">
						<h4>{{ entry.text }}</h4>
						<p>{{ entry.text }}</p>
						<h6>{{ formatDate(entry.createdAt) }}</h6>
					</div>
				</v-card>
			</div>

			<!-- CUSTOMER-MESSAGES -->
			<div v-if="entry.type === 'customer-message'">
				<v-card elevation="1" class="mb-4 timeline-card d-flex">
					<div class="card-icon"><v-icon color="black">mdi-message-outline</v-icon></div>
					<div class="timeline-card-content">
						<h4>Nachricht von {{ entry.customer.firstname }} {{ entry.customer.lastname }}</h4>
						<p class="quote">"{{ entry.text }}"</p>
						<h6>{{ formatDate(entry.createdAt) }}</h6>
					</div>
				</v-card>
			</div>

			<!-- MESSAGE -->
			<div v-if="entry.type === 'message'">
				<v-card elevation="1" class="mb-4 timeline-card d-flex">
					<div class="card-icon"><v-icon color="black">mdi-message</v-icon></div>
					<div class="timeline-card-content">
						<h4>Nachricht von werc / {{ entry.user.firstname }}</h4>
						<p class="quote">"{{ entry.text }}"</p>
						<h6>{{ formatDate(entry.createdAt) }}</h6>
					</div>
				</v-card>
			</div>
		</div>

		<!-- <div v-for="(entry, idx) in orderedTimeline" :key="idx + '_timeline_entry'">
            <v-card elevation="1" class="mb-4 timeline-card">
                <div class="timeline-card-title d-flex">
                    <div class="card-icon"><v-icon large>mdi-done-outlined</v-icon></div>
                    <div>
                        <h6>{{ formatDate(entry.createdAt) }}</h6>
                        <h4>{{ entry.text }}</h4>
                    </div>
                </div>
                <div class="separator"></div>
                <div class="timeline-card-content">
                    <div class="message-quote">
                        <div class="author">Claudia</div>
                        <p>
                            Lorizzle ipsum dolizzle sizzle amet, shizzle my nizzle crocodizzle adipiscing elit. Dope fo shizzle
                            velizzle, sizzle volutpizzle, suscipit dawg, bizzle vizzle, black. Pellentesque shizznit tortor.
                            Away eros. Mofo at dolor tellivizzle shizznit tempizzle phat.
                        </p>
                    </div>
                    <div class="message-changes">
                        <div class="change-counter">
                            Leider können wir deine Anfrage so nicht umsetzten aber würden folgende Änderungen vorschlagen.
                        </div>
                        <ul>
                            <li>
                                Änderung der Menge von <span class="old">25</span> auf <span class="new">30</span> für den
                                Artikel "Creator Rot".
                            </li>
                        </ul>
                        <p>
                            Wenn du mit diesen Änderungen einverstanden bist kannst du das Angebot bestätigen und wir legen los.
                        </p>
                    </div>
                </div>
            </v-card>
        </div> -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import orderBy from 'lodash/orderBy'
import dayjs from 'dayjs'

export default {
	name: 'Timeline',
	computed: {
		...mapGetters({
			currentProject: 'Requests/getCurrentRequest',
		}),
		orderedTimeline: function () {
			return orderBy(this.currentProject.timeline, ['createdAt'], ['desc'])
		},
	},
	methods: {
		formatDate(date) {
			return dayjs(date).format('DD.MM.YYYY, HH:mm')
		},
	},
}
</script>

<style lang="sass" scoped>
.timeline-card
	width: 100%
	.card-icon
		padding: 25px 6px 6px 30px
		margin-right: 5px

	.timeline-card-content
		padding: 25px 15px 25px 15px
		width: 100%
		.quote-card
			width: 450px // temporary
	.separator
		height: 1px
		background: rgb(230,230,230)
		margin: 15px 0
	h6
		font-weight: 400
		letter-spacing: 0em
		color: rgb(100,100,100)
	h4
		font-size: 1.7em
		letter-spacing: -0.04em
		line-height: 1.1
		font-weight: 700
		margin-bottom: 10px
	p
		margin-bottom: 5px
		&.quote
			font-weight: 300
			font-size: 1.3em
			line-height: 1.8
			white-space: pre-wrap
	.message-quote
		display: inline-flex
		align-items: flex-start
		justify-content: flex-start
		padding-top: 20px
		.author
			font-weight: 700
			padding-right: 30px
			padding-top: 0.2em
		p
			line-height: 2
			font-weight: 300
			font-size: 1.2em
	.message-changes
		.change-counter
			font-weight: 700
		ul
			li
				font-size: 1.1em
				font-weight: 300
				padding: 10px 0
				span
					font-weight: 700
					&.old
						font-weight: 700
</style>